import type { ThemeConfig } from '@/plugins/config';
import type { IAssignment } from '@/store/modules/assessment/types';

export type Token = { token: string; redirect?: string };
export type ApiKey = { api_key: string; type: string };

export enum ErrorType {
  'E400' = 'E400',
  'E401' = 'E401',
  'E420' = 'E420',
  'E428' = 'E428',
  'E500' = 'E500',
  'E504' = 'E504',
  'E1600' = 'E1600',
  'E1601' = 'E1601',
  'E1606' = 'E1606',
  'E1607' = 'E1607', // is forced finished section
  'E1608' = 'E1608', // is locked section
  'E1612' = 'E1612', // invalid state (async save answer)
  'E1613' = 'E1613', // max attempts reached (async save answer)
  'E1203' = 'E1203', // dsat/dpsat module 1 not completed for module 2
  'ENET' = 'Connection',
  'ETIMEDOUT' = 'ETIMEDOUT',
}

export interface IErrorDataMessage {
  showCancel: boolean;
  closeButtonLabel: string;
  modalClasses: string[];
  message: string;
  onCloseAction: (err: Error) => void;
  type: ErrorType;
}

export interface RootState {
  appName: string;
  appVersion: string;
  theme: ThemeConfig;
  isOnline: boolean;
  productPrefix: string;
  product: string;
  isLoading: boolean;
  replaceAppHeader?: boolean;
}

/*
 * Types from BASICS are used across all of the modules on CERT: Students App
 * It is normal to see types that are related to specific modules, such as Auth or Accounts
 * The main goal is to decrease the size of each function by not adding entire modules when it is not needed
 */
export interface IBasicsOrg {
  orgid: number;
  channel_id: number;
  parent_id: number;
  grade12_allyear: 1 | 0;
  single_test: 1 | 0;
  mq_middle_access: 1 | 0;
  mq_high_access: 1 | 0;
}

export interface IBasicsAuthToken {
  uid: number;
  name: string;
  username: string;
  usertype: 'student' | 'parent' | 'admin';
  grade: number;
  grade_type: 'high' | 'middle';
  edition_id: number;
  uproduct_id: number;
  permissions: IBasicsAuthPermissions;
  iat: number;
  exp: number;
}

export interface IBasicsAssessmentPeriods {
  id: number;
  key: BasicsWindows;
  starts_at: string;
  ends_at: string;
  channel_id: number;
  orgid: number;
  custom: 0 | 1;
}

export interface IBasicsAuthPermissions {
  homeRoom: 1 | 0;
  examRoom: 1 | 0;
  studyHall: 1 | 0;
  quizzes: 1 | 0;
  courseReport: 1 | 0;
  readinessReport: 1 | 0;
  sat: 1 | 0;
  gp: 1 | 0;
  scoreProgressionReport: 1 | 0;
  enforcedTimeLimits: 1 | 0;
  onlineTesting: 1 | 0;
  offlineEntry: 'student' | 'admin' | null;
  dashboard: 1 | 0;
  periods: IBasicsAssessmentPeriods[];
  orgPeriods: number[];
  tsiPeriods: number[];
}

export interface IBasicsUid {
  id: number;
}

export interface IBasicsAccountLogin {
  uid: number;
  reference_date: number;
  timestamp: number;
  navigation_data: any;
}

export interface IBasicsAccountProduct {
  id: number;
  uid: number;
  edition_id: number;
  edition_sku: string;
  dt_expire: string;
  locked: 0 | 1;
}

export interface IBasicsSectionParams {
  subject: BasicsSubjects;
  test_num: string;
}

export interface IBasicsSectionRedux extends IBasicsSectionParams {
  score: number;
  feedback_score: number;
}

export interface IBasicsTestSectionParams extends IBasicsSectionParams {
  grade: number;
  periods: IBasicsAssessmentPeriods[];
}

export interface IBasicsFriendlyError {
  code: number;
  message: string;
}

export interface IBasicsScoreRanges {
  english: number;
  math: number;
  reading: number;
  science: number;
}

export interface IBasicsScoreLimits {
  min: number;
  max: number;
}

export interface IBasicsHtmlResponse {
  statusCode: number;
  headers: { [index: string]: any };
  body: string;
}

export enum BasicsPeriod {
  fall = 1,
  winter = 2,
  spring = 3,
}

export const BasicsPeriodByNumber = {
  1: 'fall',
  2: 'winter',
  3: 'spring',
};

export enum BasicsWindows {
  fall = 'fall',
  winter = 'winter',
  spring = 'spring',
}

export enum BasicsTypes {
  high = 'high',
  middle = 'middle',
}

export enum BasicsSubjects {
  English = 'English',
  Math = 'Math',
  Reading = 'Reading',
  Science = 'Science',
}

export enum BasicsDSatSubjects {
  RW = 'RW',
  Math = 'Math',
}

export enum BasicsReactSubjects {
  elar = 'ELAR',
  math = 'Math',
}

export enum BasicsTsiSubjects {
  Elar = 'Elar',
  Math = 'Math',
}

export enum BasicsSatSubjects {
  Reading = 'Reading',
  Writing = 'Writing',
  Math = 'Math',
}

export type BasicsAllSubjects =
  | BasicsSubjects
  | BasicsSatSubjects
  | BasicsTsiSubjects
  | BasicsDSatSubjects
  | BasicsReactSubjects;

export enum BasicsExams {
  explore = 'explore',
  preact = 'preact',
  act = 'act',
}

export type IAnswers = Record<number, IAnswer>;

export interface IAnswersPayload {
  answers: IAnswer[];
}

// Core
export interface IUSection {
  answers: IAnswers;
  id: number;
  uid: number;
  section_id: number;
  test_num: string;
  num: number;
  title: string;
  title_short: string;
  title_test: string;
  ct: number;
  exam: 'act';
  time: number;
  time_remain: number;
  subject: BasicsAllSubjects;
  status: SectionStatus;
  is_completed: 0 | 1;
  is_force_finished: 0 | 1;
  is_locked: 0 | 1;
  is_started: 0 | 1;
  pct_review: number;
  dt_completed: string;
  i: number;
  c: number;
  s: number;
  result?: 0 | 1;
  next_question_id?: number;
  score?: number;
  is_diagnostic?: 0 | 1;
  edition?: string;

  session_type?: ReactSessionType;
  dt_start?: string;
  dt_end?: string;
  usection_id?: string;
  assignment?: IAssignment;

  explanation?: string | null;

  pct_vid_review?: number;
  pct_vid_all?: number;

  quiz_id?: string;
  assignment_id?: string;

  feedback_pct_c?: number;
  feedback_pct_i?: number;
}

export interface IUSectionReview {
  id: number;
  section_id: number;
  dt_start_review: string;
  dt_reviewed: string;
  pct_review: number;
  ct_review: number;
  pct_vid_all: number;
  status: SectionStatus;
}

export interface IRawAnswer {
  section_id: number;
  usection_id: number;
  question_id: number;
  question_num: number;
  time_spent: number;
  time_remain: number;
  answer: string;
}

export interface IAnswer {
  section_id: number;
  usection_id: number;
  question_id: number;
  question_num: number;
  time_spent: number;
  time_remain: number;
  answer: string;
  ics?: 'i' | 'c' | 's' | '';
  flagged?: boolean;
}

export interface IAnswerInsert {
  section_id: number;
  usection_id: number;
  uid: number;
  question_id: number;
  answer: string;
  time: number;
  is_c: 0 | 1;
  is_i: 0 | 1;
  is_s: 0 | 1;
  ics: 'i' | 'c' | 's';
  created_at: string;
  updated_at: string;
}

export interface ILesson {
  id: number;
  name: string;
  subject: BasicsSubjects;
}

export enum SectionStatus {
  Graded = 'Graded',
  InReview = 'In Review',
  Reviewed = 'Reviewed',
}

export interface IChoices {
  A: string;
  B: string;
  C: string;
  D: string;
  F: string;
  G: string;
  H: string;
  J: string;
}

export enum ITSIlevels {
  'Level 1' = 1,
  'Level 2' = 2,
}

export enum ContentRendererTypes {
  TEXT = 'text',
  AUDIO = 'audio',
  IMAGE = 'image',
  VIDEO = 'video',
  DA = 'da',
}

export enum BasicApps {
  CERT = 'cert',
  EPREP = 'eprep',
  HOP = 'hop',
  SYLVAN = 'sylvan',
}

export type IBasicApps = (typeof BasicApps)[keyof typeof BasicApps];

export enum BasicProducts {
  ACT = 'act',
  SAT = 'sat',
  DSAT = 'dsat',
  DPSAT = 'dpsat',
  PREACT = 'preact',
  TSI = 'tsi',
  QTA = 'qta',
  REACT = 'react',
  FSA = 'fsa',
  KSA = 'ksa',
  GP = 'gp',
  SSAT_LL = 'ssat-ll',
  SSAT_UL = 'ssat-ul',
  SAT_SUBJECT = 'sat-subject',
  PLAN = 'plan',
  DACT = 'eact',
  EACT = 'eact',
}

export type IBasicProducts = (typeof BasicProducts)[keyof typeof BasicProducts];

export enum ReactSessionType {
  OPEN = 'open',
  ASSIGNMENT = 'assignment',
}
