<template>
  <div id="app">
    <error-boundary>
      <app-loader />
      <app-themes>
        <notifications classes="custom_notification" group="error" position="bottom right" />
        <app-header v-if="showHeader" :isDemo="isDemo" />
        <main>
          <div
            class="hidden-md"
            style="
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: white;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="text-align: center">
              <h3>Phone Access Update</h3>
              <h5>Try rotating your device to landscape mode.</h5>
              <p>
                ePrep isn't working on ALL phones right now! If it still doesn't work in landscape mode, please use your
                tablet, iPad, laptop, or desktop computer until
                <b>August 1st</b>. Thanks for your understanding!
              </p>
            </div>
          </div>
          <transition name="fade" mode="out-in" appear class="default-transition">
            <router-view class="hidden-sm" />
          </transition>
        </main>
        <app-footer />
        <vue-progress-bar></vue-progress-bar>
      </app-themes>
    </error-boundary>
    <component v-if="mathjaxUrl" :src="mathjaxUrl" :is="'script'"></component>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import ErrorBoundary from '@/components/common/ErrorBoundary.vue';
import AppHeader from '@/layout/AppHeader.vue';
import AppFooter from '@/layout/AppFooter.vue';
import AppThemes from '@/AppThemes.vue';
import AppLoader from '@/layout/AppLoader.vue';
import type { ThemeConfig } from '@/plugins/config';
import type { IUser } from '@/store/modules/auth/types';

@Component({
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    ErrorBoundary,
    AppThemes,
    AppLoader,
  },
})
export default class App extends Vue {
  public currentPath: string = '/';
  public isDemo: boolean = false;
  public hasHeader: boolean = false;
  public isLogin: boolean = false;

  @State('isCompleted', { namespace: 'authModule' })
  private isCompleted!: boolean;

  @State('isLogged', { namespace: 'authModule' })
  private isLogged!: boolean;

  @Getter('theme')
  public theme!: ThemeConfig;

  @Getter('replaceAppHeader')
  public replaceAppHeader!: boolean;

  @Getter('account', { namespace: 'authModule' })
  public account!: IUser;

  @Action('setProduct')
  public setProduct!: (payload: string) => Promise<void>;

  @Watch('account', { immediate: true, deep: true })
  public onAccountChanged(val: IUser): void {
    if (val && val.activeProduct) {
      this.setProduct(val.activeProduct.product_type.toLowerCase());
    }
  }

  get showHeader() {
    return (this.isLogged && this.isCompleted && !this.replaceAppHeader) || this.hasHeader;
  }

  get isMobile() {
    return window.innerWidth < 840;
  }

  get mathjaxUrl() {
    // The default template is defined as global, therefore if I use the template var here,
    // it will always call the CERT cdn first. I can't modify the default template right now
    // because it breaks a lot of other small things, this is the only option we have for now.
    // TODO: this is the only of doing this right now
    return location.href.includes('cert') || location.href.includes('localhost:8041')
      ? 'https://cdn.certforschools.com/student-interface/mathjax/es5/tex-mml-chtml.js'
      : 'https://cdn.eprep.com/student-interface/mathjax/es5/tex-mml-chtml.js';
  }

  @Watch('$route.path', { immediate: true })
  public changeRoute(path: string): void {
    this.currentPath = path;
    this.isLogin = path === '/' || path === '/login' || path === '/forgot' || path === '/sso';
  }

  @Watch('$route.matched', { immediate: true })
  public changeRouteMatched(path: any): void {
    if (path.length) {
      this.hasHeader =
        (path[0]['name'] === 'practice' && (path.length > 1 && path[1]['name']) !== 'practice-login') ||
        path[0]['name'] === 'about';

      this.isDemo = path[0]['name'] === 'practice' && path[1]['name'] !== 'practice-login';
    }
  }
}
</script>

<style>
@media (min-width: 840px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .container {
    max-width: 100% !important;
  }
}

@media (max-width: 840px) {
  .hidden-sm {
    display: none !important;
  }
}
</style>
